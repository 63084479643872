import React, { HTMLAttributes } from "react";

import Img from "./Image";
import { ImgProps } from "./types";

const LogoImg: React.FC<ImgProps> = ({ className, src, ...props }) => {
  return <Img src={src} width={120} height={120} className={className} alt="test" />;
};

export default LogoImg;
