import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M7.99996 11.3334C8.73329 11.3334 9.33329 10.7334 9.33329 10.0001C9.33329 9.26675 8.73329 8.66675 7.99996 8.66675C7.26663 8.66675 6.66663 9.26675 6.66663 10.0001C6.66663 10.7334 7.26663 11.3334 7.99996 11.3334ZM12 5.33342H11.3333V4.00008C11.3333 2.16008 9.83996 0.666748 7.99996 0.666748C6.15996 0.666748 4.66663 2.16008 4.66663 4.00008V5.33342H3.99996C3.26663 5.33342 2.66663 5.93341 2.66663 6.66675V13.3334C2.66663 14.0667 3.26663 14.6667 3.99996 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3334V6.66675C13.3333 5.93341 12.7333 5.33342 12 5.33342ZM5.93329 4.00008C5.93329 2.86008 6.85996 1.93341 7.99996 1.93341C9.13996 1.93341 10.0666 2.86008 10.0666 4.00008V5.33342H5.93329V4.00008ZM12 13.3334H3.99996V6.66675H12V13.3334Z"
        // fill="currentColor"
      />
    </Svg>
  );
};

export default Icon;
