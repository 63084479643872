import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  LockIcon,
  SwapFillIcon,
  EarnFillIcon,
  CommunityFilledIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

export const logoConfig = {
  logoSrc: 'https://dql4d4zhzt5hs.cloudfront.net/images/logo.png',
  logoWithTextSrc: 'https://dql4d4zhzt5hs.cloudfront.net/images/logo-with-text.png',
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Swap'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Liquidity'),
      href: '/liquidity',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      image: 'https://dql4d4zhzt5hs.cloudfront.net/images/decorations/pe2.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('LOCKS'),
      href: 'https://www.maplesales.org/mapLock/create',
      icon: LockIcon,
      fillIcon: LockIcon,
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },

    {
      label: t('AITRADINGS'),
      href: `https://aitradings.momorobot.org/`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      showItemsOnMobile: false,

      items: [],
    },
    // {
    //   label: '',
    //   href: '',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('LOCK'),
    //       href: 'https://www.maplesales.org/mapLock/create',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('AITRADINGS'),
    //       href: 'https://aitradings.momorobot.org/',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       type: DropdownMenuItemType.DIVIDER,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
